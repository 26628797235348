import {Enum} from "../../../../utils/enum.utils";
import {CommonDoc} from "../../../../shared/services/firebase/domain.firebase";

export const Payment = {
    colName: "payments",

    keys: "keys",
    ...CommonDoc,
}

export const PaymentType = Enum({
    IN_PROGRESS: {label: "En Cours"},
    FAILED: {label: "Echouée"},
    CANCELLED: {label: "Annulée"},
    SUCCESS: {label: "Succèss"},
    VALID: {label: "Validé"},
});

