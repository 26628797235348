import {CommonDoc} from "../../../../shared/services/firebase/domain.firebase";
import {Enum} from "../../../../utils/enum.utils";

export const Ticket = {
    colName: "tickets",
    shareTokens: "shareTokens",

    ...CommonDoc,
}

export const TicketStatus = Enum({
    INITIAL: {initial: true},
    IN_VALIDATION: {inValidation: true},
    USED: {used: true},
    CANCEL: {cancel: true},
    TRANSFERRED: {transferred: true},
});

