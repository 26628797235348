import {useEffect} from "react";
import {useQuery} from "react-query";
import {atom, useRecoilState} from "recoil";
import {ko, ok} from "../../utils/service.utils";
import {apiPublicUrl} from "../../utils/http.common";
import {paymentCol, PaymentStatus} from "./payment_domains";
import {createMyTicket} from "../my-tickets/my.ticket.service";
import {Payment} from "../backoffice/general/payments/payment.domains";
import {initialSearchQueryData} from "../../shared/services/firebase/firebase.cache.store";
import {endAt, limit, orderBy, query as _query, query, startAfter, where} from "firebase/firestore";
import {
    auditorCreate,
    auditorUpdate,
    countFromServer,
    crudService,
    execQueryForList,
    service
} from "../../shared/services/firebase/db.firebase";
import {Event} from "../backoffice/general/events/event.domains";
import {apiClient, urlBuilder} from "../../axios/axios.service";

const colName = "payments";

export const paymentsPublicUrl = `${apiPublicUrl}/${paymentCol}`;
export const paymentCallBackUrl = `${paymentsPublicUrl}/checkPayment?reference=`;

const {urlFn} = urlBuilder("bigquery");

export const {
    colRef,
    docRef,
    create,
    update,
    remove,
    findById,
    findAll,
    newDocRef,
    count,
} = crudService(colName);

export const {update: _updateField} = service(colName);

const paymentsState = atom({
    key: "payments.state",
    default: {...initialSearchQueryData},
});

export const createPayment = async (payment) => {
    if (!payment) return ko("Aucune donnée de paiement");
    if (!payment?.data?.event) return ko("Ce paiement n'est lié à aucun évènement");
    try {
        const paymentRef = await create(auditorCreate(payment));
        return ok({...payment, id: paymentRef.id});
    } catch (e) {
        const message = "Erreur lors de la création du paiement";
        console.error(message, e);
        return ko(message);
    }
}

export const updatePayment = async (payment, status) => {
    if (!payment || !payment.id || !payment.data) return ko("Aucune donnée de paiement");
    if (!payment?.data?.event?.id) return ko("Ce paiement n'est lié à aucun évènement");
    try {
        const {update: updatePayment, docRef: paymentDocRef} = service(Payment.colName);
        await updatePayment(paymentDocRef(payment.id), auditorUpdate({status}));
        return ok(payment, "Paiement mis à jour avec succès");
    } catch (error) {
        console.log("error ==> ", error);
        return ko("Erreur lors de la mise à jour du paiement");
    }
}

export const updatePaymentField = async (payment, fieldAndValue) => {
    try {
        if (!payment?.id) return ko("Ce paiement n'existe pas");
        if (!fieldAndValue) return ko("Merci de donner un champ au moins");
        await _updateField(docRef(payment.id), auditorUpdate(fieldAndValue));
    } catch (error) {
        console.log('error ==> ', error);
        return ko("Erreur lors de la mise à jour du paiement");
    }
}

export const updatePaymentAndCreateTicket = async (payment) => {
    try {
        if (!payment?.id) return ko("Ce paiement n'existe pas");
        await _updateField(docRef(payment.id), auditorUpdate({status: PaymentStatus.valid}));
        return await createMyTicket(payment.id);
    } catch (error) {
        console.log('error ==> ', error);
        return ko("Erreur lors de la création de ticket");
    }
}


export const validatePaymentFromAdmin = async (payment) => {
    try {
        return updatePaymentAndCreateTicket(payment);
    } catch (error) {
        console.log('error ==> ', error);
        return ko("Erreur lors de la création de ticket");
    }
}

export const useFindById = (id) => {
    const queryKey = [colName, "by.id", id];
    const queryFn = () => {
        if (!id) return null;
        return findById(id);
    }
    return useQuery(queryKey, queryFn, {});
}

export const usePaymentCount = () => {
    const query = _query(colRef, where("status", "==", "VALID"));
    const queryFn = () => countFromServer(query);

    const queryKey = [`GET_PAYMENTS_COUNT`];
    const {data: count, ...others} = useQuery(queryKey, queryFn, {staleTime: 5000});
    return {count, ...others};
};

export const useFindPaymentByUser = (user, order = "desc") => {
    const queryKey = [colName, "my.payments", user?.uid];
    const queryFn = async () => {
        if (!user) return [];
        return await execQueryForList(query(
            colRef, where("userId", "==", user.uid), orderBy("createdDate", order)
        ));
    }
    const {data: payments, ...others} = useQuery(queryKey, queryFn);
    return {payments, ...others};
}

export const findPaymentById = (id) => {
    if (!id) return;
    return findById(id);
}

const searchPayments = async ({query, size = 20, paymentStatus = "all", startingField, endingField}) => {
    let fetchQuery = colRef;
    if (query) {
        fetchQuery = _query(fetchQuery, where(Event.keys, "array-contains", query));
    }
    if (paymentStatus !== "all") {
        fetchQuery = _query(fetchQuery, where("status", "==", paymentStatus));
    }
    fetchQuery = _query(fetchQuery, orderBy("createdDate", "desc"), limit(size));

    if (startingField) fetchQuery = _query(fetchQuery, startAfter(startingField));
    if (endingField) fetchQuery = _query(fetchQuery, endAt(endingField));

    return await execQueryForList(fetchQuery);
}

export const useSearchPayments = () => {
    const [queryData, setQueryData] = useRecoilState(paymentsState);
    const {query, paymentStatus} = queryData;

    const queryKey = [colName, "SEARCH_PAYMENTS", query, paymentStatus];

    const queryFn = () => searchPayments({query});

    const {data, ...others} = useQuery(queryKey, queryFn);

    useEffect(() => {
        if (!data) return;
        setQueryData(queryData => ({...queryData, data}));
    }, [data, setQueryData]);

    const setQuery = (query) => {
        setQueryData(queryData => ({...queryData, query}));
    }

    const setPaymentStatus = (paymentStatus) => {
        setQueryData(queryData => ({...queryData, paymentStatus}));
    }
    return {...queryData, ...others, setQuery, setPaymentStatus}
}

export const usePaymentPagination = () => {
    const [queryData, setQueryData] = useRecoilState(paymentsState);
    const {data} = queryData;

    const endingField = data?.[data?.length - 1]?.createdDate;
    const startingField = data[0]?.createdDate;

    const hasNext = () => false;
    const next = async () => {
        if (!startingField) return;
        const payments = await searchPayments({startingField: endingField});
        setQueryData(queryData => ({...queryData, data: [...payments]}));
    };

    const hasPrevious = () => false;
    const previous = async () => {
        if (!endingField) return;
        const payments = await searchPayments({endingField: startingField});
        setQueryData(queryData => ({...queryData, data: [...payments]}));
    }
    return {hasNext, next, hasPrevious, previous};
}
