import {Enum} from "../../utils/enum.utils";

export const paymentCol = 'payments';
export const paymentRef = `${paymentCol}/{paymentId}`;

export const configurationCol = 'configurations';

export const PaymentMethod = Enum({
    OM: {label: "Orange Money", imageUrl: "/images/orange_money.png", isOrangeMoney: true},
    // MM: {label: "Moov Money", imageUrl: "/images/moov_money.png", isMoovMoney: true},
    CASH: {label: "Cash", imageUrl: "/images/cash-payment.jpeg", isCash: true},
    VISA: {label: "VISA", imageUrl: "/images/cash-payment.jpeg", isVisa: true},
})

export const PaymentConfig = {
    email: "email",
    password: "password",
    client_id: "client_id",
    url_auth: "url_auth",
    url_base: "url_base",
    doc_api_token: "doc_api_token"
}


export const PaymentStatus = {
    initial: 'INITIAL',
    inProgress: 'IN_PROGRESS',
    valid: 'VALID',
    error: 'ERROR',
    cancelled: 'CANCELLED',
    closed: 'CLOSED',
    failed: 'FAILED',
    VALID: 'valid',
}

export const PaymentType = {
    subscription: 'Subscription',
    renew: 'Renew',
    changeOffer: 'ChangeOffer',
}

export const paymentField = {
    keys: 'keys',
    status: 'status',
    type: 'type',
    userId: 'userId',
    amount: 'amount',
    createdDate: 'createdDate',
    validationDate: 'validationDate',
    newSubscriptionData: 'newSubscriptionData',
}

export const PaymentStatusDictionary = {
    INITIAL: 'INITIAL',
    IN_PROGRESS: 'En cours',
    VALID: 'Validé',
    ERROR: 'Erreur',
    CANCELLED: 'Annulée',
    CLOSED: 'Annulée',
    FAILED: 'Echoué',
}


