import {atom} from "recoil";
import {range} from "lodash";
import {useQuery} from "react-query";
import {doc, getDoc, onSnapshot, orderBy, query} from "firebase/firestore";

import {ko, ok} from "../../utils/service.utils";
import useAuth from "../../shared/hooks/useAuth";
import {findPaymentById} from "../payment/payment.service";
import {Ticket, TicketStatus} from "../backoffice/general/tickets/ticket.domains";
import {initialSearchQueryData, useSearchDocs} from "../../shared/services/firebase/firebase.cache.store";
import {
    auditorCreate,
    collectionRef,
    crudService,
    documentRef,
    execQueryForList,
    extractData,
    runTransaction
} from "../../shared/services/firebase/db.firebase";
import {useEffect, useState} from "react";

const collectionPath = (userId) => `users/${userId}/${Ticket.colName}`;
const documentPath = (userId, ticketId) => `${collectionPath(userId)}/${ticketId}`;
const collectionReference = (userId) => collectionRef(collectionPath(userId));
const newDocumentReference = (userId) => doc(collectionReference(userId));
const documentReference = (userId, ticketId) => documentRef(documentPath(userId, ticketId));

export const {
    colRef, docRef, create, update, remove,
    findAll, newDocRef, count
} = crudService(Ticket.colName);

const ticketsState = atom({
    key: "my.tickets.state",
    default: {...initialSearchQueryData},
});

export const useSearchTickets = () => {
    return useSearchDocs({
        colRef, state: ticketsState, queryName: "SEARCH_TICKETS"
    });
}

export const useFindById = (id) => {
    const {user} = useAuth();
    const userId = user?.uid;

    const queryKey = [Ticket.colName, "findById", userId, id];

    const queryFn = () => {
        if (!id || !userId) return {};
        return findById(userId, id);
    }

    return useQuery(queryKey, queryFn, {});
}

const findById = async (userId, ticketId) => {
    const ref = documentReference(userId, ticketId);
    const snap = await getDoc(ref);
    return extractData(snap);
}

export const useFindByRef = (path) => {
    const queryKey = [path];
    const queryFn = async () => {
        if (!path) return {};
        const ref = documentRef(path);
        const snap = await getDoc(ref);
        return extractData(snap);
    }
    const {data, ...others} = useQuery(queryKey, queryFn, {});
    console.log("data === ", data);
    return {ticket: data, ...others};
}

export const useFindByUserAndId = (userId, ticketId) => {
    const queryKey = [userId, ticketId];
    const queryFn = () => {
        if (!userId || !ticketId) return {};
        return findById(userId, ticketId);
    };
    const {data, ...others} = useQuery(queryKey, queryFn, {});
    return {ticket: data, ...others};
}

export const useFindMyTickets = (userId) => {
    const queryKey = ["FIND_MY_TICKETS", userId];
    const queryFn = async () => {
        const _query = query(collectionReference(userId));
        return await execQueryForList(_query);
    }
    const {data, ...others} = useQuery(queryKey, queryFn, {});
    return {tickets: data, ...others};
}

export function useFindMyTicketsRealtime(userId) {
    const [isLoading, setIsLoading] = useState(true);
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        if (!userId) return;
        const q = query(collectionReference(userId), orderBy("createdDate", "desc"));
        onSnapshot(q, (querySnapshot) => {
            setTickets(querySnapshot.docs.map(extractData));
            setIsLoading(false);
        });
    }, [userId, setTickets]);

    return {tickets, isLoading};
}


export const createMyTicket = async (paymentId) => {
    try {
        const payment = await findPaymentById(paymentId);

        const {paymentMethod, amount, userDisplayName, userId, sellerDisplayName, sellerId, data, itemCount} = payment;
        const {offer, event, ...others} = data;
        const eventRef = documentRef(event.ref);

        const tickets = range(itemCount).map(_ => ({
            ticketRef: newDocumentReference(userId),
            ticket: auditorCreate({
                offer,
                transactionId: paymentId,
                paymentMethod,
                amount,
                userDisplayName,
                userId,
                sellerDisplayName,
                sellerId,
                event,
                eventId: event.id,
                status: TicketStatus.INITIAL.name,
                data: others
            })
        }));

        const {status, message} = await runTransaction(async (transaction) => {
            const refreshEvent = extractData(await transaction.get(eventRef));
            if (!refreshEvent) return ko("Cet évènement n'existe pas");

            const {remainingSeatCount} = refreshEvent;
            if (remainingSeatCount <= 0) return ko("Désolé, Il ne reste plus de ticket disponible pour cet evenement");
            if (remainingSeatCount < itemCount) return ko(`Désolé, Le nombre de ticket restant (${remainingSeatCount}) est insuffisant`);

            transaction.update(eventRef, {remainingSeatCount: remainingSeatCount - itemCount});
            tickets.forEach(({ticketRef, ticket}) => transaction.set(ticketRef, ticket));

            return ok(tickets);
        });

        if (!status) return ko(message);

        const ids = tickets.map(({ticketRef: {id}}) => id);
        const s = itemCount > 1 ? "s" : "";

        return ok(ids, `${itemCount} Ticket${s} créé${s} avec succès`);

    } catch (error) {
        console.log("Create My Ticket Error ==> ", error);
        return ko("Erreur lors de la création du billet");
    }
}

export const useAllTickets = (userId) => {
    const queryKey = ["FIND_MY_TICKETS", userId];
    const queryFn = async () => {
        const colRef = collectionReference(userId);
        const _query = query(colRef);
        const tickets = await execQueryForList(_query);
        return tickets;
    }
    const {data, ...others} = useQuery(queryKey, queryFn, {});
    return {tickets: data, ...others};
}






