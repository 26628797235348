import axios from "axios";
import {auth} from "../shared/services/firebase/auth.firebase";

export const apiClient = axios.create({baseURL: '/api/bigquery'});

export const urlBuilder = (path) => ({
    path,
    urlFn: (operation = "") => `${path}/${operation}`
});

apiClient.interceptors.request.use(async (config) => {
    try {
        const accessToken = await auth.currentUser?.getIdToken();
        if (!accessToken) return config;

        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    } catch (e) {
        console.log(e);
    }
});

